import styled from 'styled-components'
import { tertiary } from '../../variables'

export default styled.div`
  margin-bottom: 1em;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column' };

  label {
    margin-right: 1.5em;
    display: block;
    font-size: 0.75em;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0.4em;
  }

  input[type="text"],
  input[type="email"] {
    min-width: 200px;
    max-width: 400px;
    font-size: 1em;
    padding: 0.4em;
    background: white;
    border: 1px solid ${tertiary};
  }
`
