import styled from 'styled-components'

export default styled.h1`
  font: bold 2em arial, sans-serif;
  background-color: #444;
  color: #222;
  margin: 0 0 0 0.5em;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.3);
  text-transform: lowercase;
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
`
