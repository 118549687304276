
import { getAuth } from 'firebase/auth'

import ReactGA from 'react-ga'

// Anonymous user set Google Analytics VALUE to 0
const getDefaultValue = () => {
  return getAuth().currentUser.isAnonymous === true ? 0 : 1
}

export default ({ action, category, label, value = getDefaultValue() }) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  })
}
