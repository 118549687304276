import React from 'react'
import { Link } from 'react-router-dom'

import { InfoBox } from './styled'

export default ({ posts }) => {
  return (
    <InfoBox>
      This sortada spawned the following (re)Sortadas: {posts.map(x => <Link key={`spawn_${x.id}`} to={`/postulation/${x.id}`}><br />{x.title}</Link>)}
    </InfoBox>
  )
}
