import React from 'react'
import { confirmable } from 'react-confirm'

import { ConfirmModal, ConfirmContent, ConfirmMessage, ConfirmButtons } from './styled'
import { Button } from '../styled/Button'

const preventThen = (e, fn) => {
  e.preventDefault()
  fn()
}

const DialogBox = ({ show, proceed, cancel, confirmation, confirmLabel = 'Yes', cancelLabel = 'Cancel' }) => (
  <ConfirmModal show={show}>
    <ConfirmContent>
      <ConfirmMessage>{confirmation}</ConfirmMessage>
      <ConfirmButtons>
        <Button onClick={(e) => preventThen(e, proceed)}>{confirmLabel}</Button>
        <Button onClick={(e) => preventThen(e, cancel)}>{cancelLabel}</Button>
      </ConfirmButtons>
    </ConfirmContent>
  </ConfirmModal>
)

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(DialogBox)
