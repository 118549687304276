import React, { useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged, signInAnonymously, signOut, signInWithPopup, FacebookAuthProvider, GithubAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth'
import { getDatabase, ref, onValue } from 'firebase/database'

import _isUndefined from 'lodash.isundefined'

import { GA } from '../constants'
import GAEvent from '../modules/analytics-event-helper'

const AuthContext = React.createContext()

const providers = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  TWITTER: 'TWITTER',
  GITHUB: 'GITHUB'
}

const AuthProvider = ({ children }) => {
  const [anonymous, setAnonymous] = useState()
  const [user, setUser] = useState()
  const [userScore, setUserScore] = useState()
  const [userName, setUserName] = useState()
  const [userLoading, setUserLoading] = useState(true)

  useEffect(() => {
    let loadingComplete = false

    if (anonymous) {
      // if an anonymous user is found, loading is always complete
      loadingComplete = true
    } else {
      // if not wait until calls for userScore and userName have at least finished
      loadingComplete = !_isUndefined(userScore) && !_isUndefined(userName)
    }

    setUserLoading(!loadingComplete)
  }, [user, userName, anonymous, userName])

  useEffect(() => {
    onAuthStateChanged(getAuth(), authUser => {
      if (authUser) {
        if (authUser.isAnonymous === true) {
          setUser(authUser)
          setAnonymous(authUser)
        } else {
          setUser(authUser)
          setAnonymous(undefined)
          // attempt to get totalScore and userName if non-anonymous user
          onValue(ref(getDatabase(), `userActivity/${authUser.uid}/totalScore`), (scoreSnapshot) => {
            setUserScore(scoreSnapshot.val())
          })
          onValue(ref(getDatabase(), `profiles/${authUser.uid}/userName`), (profileSnapshot) => {
            setUserName(profileSnapshot.val())
          })
        }
      } else {
        signInAnonymously(getAuth()).catch((err) => {
          console.log('anonymous login error', err)
        })
      }
    })
  }, [])

  const refreshProfile = () => {
    if (user) {
      onValue(ref(getDatabase(), `profiles/${user.uid}/userName`), (profileSnapshot) => {
        setUserName(profileSnapshot.val())
      })
    }
  }

  const handleFacebookSuccess = (result) => {
    // The signed-in user info.
    const user = result.user
    setUser(user)
  }

  const handleFacebookError = () => {

  }

  const login = (providerKey = providers.FACEBOOK) => {
    GAEvent({
      category: GA.CATEGORY.LOGIN,
      action: GA.ACTION.LOGIN_FACEBOOK,
      label: 'na'
    })

    let provider

    switch (providerKey) { // TODO: this can't be right
      case providers.FACEBOOK:
        provider = new FacebookAuthProvider()
        break
      case providers.GOOGLE:
        provider = new GoogleAuthProvider()
        break
      case providers.TWITTER:
        provider = new TwitterAuthProvider()
        break
      case providers.GITHUB:
        provider = new GithubAuthProvider()
        break
      default:
    }

    signInWithPopup(getAuth(), provider)
      .then(result => handleFacebookSuccess(result))
      .catch(error => handleFacebookError(error))
  }

  const logout = () => {
    GAEvent({
      category: GA.CATEGORY.LOGIN,
      action: GA.ACTION.LOGOUT,
      label: 'na'
    })

    signOut(getAuth()).then(() => { setUser(null) })
  }

  return (
    <AuthContext.Provider
      value={{
        anonymous: anonymous,
        user: user,
        userName: userName,
        userLoading: userLoading,
        userScore: userScore,
        login: login,
        logout: logout,
        refreshProfile: refreshProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
const AuthConsumer = AuthContext.Consumer

export { AuthContext, AuthProvider, AuthConsumer, providers }
