function getRandomInt (min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default authUser => {
  if (authUser.email) {
    return authUser.email.substring(0, authUser.email.indexOf('@'))
  }
  if (authUser.displayName) {
    if (authUser.displayName.indexOf(' ') > -1) {
      return `${authUser.displayName.substring(0, authUser.displayName.indexOf(' '))}${getRandomInt(1000, 9999)}`
    } else {
      return `${authUser.displayName}${getRandomInt(1000, 9999)}`
    }
  }
  return `User${getRandomInt(1000, 9999)}`
}
