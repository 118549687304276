import React, { useContext, useEffect, useState } from 'react'
import { getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth'

import { Button, Container, Input as InputContainer, InfoBox, SingleCenteredColumn } from '../components/styled'

import { AuthContext, providers } from '../auth/AuthContext'
import MetaTags from '../components/MetaTags'


import { GoogleLoginButton, GithubLoginButton } from 'react-social-login-buttons'

import ReactGA from 'react-ga'
import { GA } from '../constants'

import GAEvent from '../modules/analytics-event-helper'

const getBaseUrl = (url = window.location) => {
  if (url.port) {
    return `${url.protocol}//${url.hostname}:${url.port}`
  }
  return `${url.protocol}//${url.hostname}`
}

const parseQuery = (qs) => {
  const values = {}
  try {
    const parts = qs.replace('?', '').split('&')
    parts.forEach((part) => {
      const keyValue = part.split('=')
      values[keyValue[0]] = keyValue[1]
    })
  } catch (e) {

  }
  return values
}

const EmailLogin = ({ history }) => {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState()

  const { anonymous, user, login } = useContext(AuthContext)
  const deepLink = decodeURIComponent(parseQuery(window.location.search).redirect)
  const redirect = window.previousLocation || '/'

  if (user && !anonymous) {
    setTimeout(() => {
      history.push(redirect)
    }, 1000)
  }

  useEffect(() => {
    ReactGA.pageview('/login')
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        GAEvent({
          category: GA.CATEGORY.LOGIN,
          action: GA.ACTION.LOGIN_PROMPT_EMAIL,
          label: 'na'
        })

        email = window.prompt('Please provide your email for confirmation')
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(getAuth(), email, window.location.href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn')

          GAEvent({
            category: GA.CATEGORY.LOGIN,
            action: GA.ACTION.LOGIN_COMPLETE_EMAIL,
            label: 'na'
          })

          if (deepLink) {
            history.push(deepLink)
          }
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          GAEvent({
            category: GA.CATEGORY.LOGIN,
            action: GA.ACTION.LOGIN_FAILED_EMAIL,
            label: 'na'
          })
          console.log(error)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        })
    }
  }, [])

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    window.localStorage.setItem('emailForSignIn', e.target.value)
  }

  const handleSendLoginLink = () => {
    setEmailSent(true)

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: `${getBaseUrl()}/login?redirect=${redirect}`,
      // This must be true.
      handleCodeInApp: true
    }

    GAEvent({
      category: GA.CATEGORY.LOGIN,
      action: GA.ACTION.LOGIN_INIT_EMAIL,
      label: 'na'
    })

    sendSignInLinkToEmail(getAuth(), email, actionCodeSettings)
  }

  return (
    <>
      <MetaTags title='Sortada.com: Login' />
      <Container className='login-container'>
        <InfoBox>We don’t do passwords at sortada.com.<br /><br />
          To login or create a new account, enter your email and click Send Login Link OR Continue with Facebook.<br />
          <a target='_whole' href='https://thisdata.com/blog/an-introduction-to-passwordless-logins/'>Passwordless Login Info</a>
        </InfoBox>
        <SingleCenteredColumn>
          {(emailSent && (!user || anonymous)) ? <h1>Login link sent, check your email</h1> : null}
          {(!emailSent && (!user || anonymous))
            ? (
              <>
                <div>
                  <InputContainer key='emailInputContainer'>
                    <label htmlFor='email'>Email</label>
                    <input type='email' name='email' value={email} onChange={handleEmailChange} />
                  </InputContainer>
                  <Button primary type='button' onClick={handleSendLoginLink}>Send Login Link</Button>
                </div>
                <div style={{ marginBottom: '2em', marginTop: '1em' }}>-- or --</div>
                {/* <div>
                  <FacebookLoginButton onClick={e => login(providers.FACEBOOK)} />
                </div> */}
                <div>
                  <GoogleLoginButton onClick={e => login(providers.GOOGLE)} />
                </div>
                <div>
                  <GithubLoginButton onClick={e => login(providers.GITHUB)} />
                </div>
                {/* <div>
                  <TwitterLoginButton onClick={e => login(providers.TWITTER)} />
                </div> */}
              </>
              )
            : null}
          {user && !anonymous && <h1>Login successful! You will be redirected.</h1>}
        </SingleCenteredColumn>
      </Container>
    </>
  )
}

export default EmailLogin
