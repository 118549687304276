export const GA = {
  CATEGORY: {
    POST: 'Post',
    PROFILE: 'Profile',
    QUICK: 'QuickPost',
    LOGIN: 'Login'
  },
  ACTION: {
    LOGIN_PROMPT_EMAIL: 'LoginPromptEmail',
    LOGIN_COMPLETE_EMAIL: 'LoginCompleteEmail',
    LOGIN_INIT_EMAIL: 'LoginInitEmail',
    LOGIN_FACEBOOK: 'LoginInitFacebook',
    LOGIN_FAILED_EMAIL: 'LoginFailedEmail',
    LOGOUT: 'Logout',
    SORT: 'Sort',
    TABVIEW: 'TabView',
    CREATED: 'Created',
    VOTED: 'Voted',
    CLOSED: 'Closed',
    DELETED: 'Deleted',
    VIEW_RESULTS: 'ViewResults',
    TOO_LATE: 'TooLate',
    VIEW_VOTING: 'ViewVoting',
    VIEW_PUBLIC: 'ViewPublic',
    VERFIY_USERNAME: 'VerifyUserName'
  }
}
