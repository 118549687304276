import { useEffect, useState } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'

const _each = require('lodash.foreach')
const _keys = require('lodash.keys')

const prepareBoard = (inData) => {
  const board = {}
  _each(inData, record => {
    if (!board[record.score]) {
      board[record.score] = []
    }
    board[record.score].push(record.userName)
  })

  const arrBoard = []

  _each(_keys(board), scoreKey => {
    arrBoard.push({ score: scoreKey, users: board[scoreKey] })
  })

  return arrBoard.sort((a, b) => a > b ? 1 : -1)
}

const useLeaderboardScores = ({ postId }) => {

  const [loading, setLoading] = useState(true)
  const [leaders, setLeaders] = useState()
  const [voteCount, setVoteCount] = useState()
  const [error, setError] = useState()
  
  useEffect(() => {
    setLoading(true)
    const getPostUserScores = httpsCallable(getFunctions(), 'getPostUserScoresV2')

    getPostUserScores({ postId }).then((results) => {
      setLeaders(prepareBoard(results.data))
      setVoteCount(results.data.length)
      setLoading(false)
    }).catch((error) => {
      setError ('Error loading leaderboard')
      setLoading(false)
    })
  }, [postId])

  return {
    leaders,
    voteCount,
    loading,
    error
  }
}

export default useLeaderboardScores