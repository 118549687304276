import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { hover, tertiary } from '../../variables'

export const Button = styled.button`
  text-align: center;
  border: 1px solid ${tertiary};
  padding: 0.3em 1em;
  border-radius: 3px;
  display: inline-block;
  font-size: 1em;
  font-weight: 500;
  background: ${props => props.tertiary ? tertiary : 'white'};
  color: ${props => props.tertiary ? 'white' : tertiary};
  margin-bottom: 1em;

  &:hover {
    background: ${hover};
  }

  &:disabled {
    opacity: 0.4;
  }

  + button {
    margin-left: 0.8em;
  }
  cursor: pointer;
`

export const RouterButton = styled(Link)`
  text-align: center;
  border: 1px solid ${tertiary};
  padding: 0.3em 1em;
  border-radius: 3px;
  font-size: 1em;
  font-weight: 500;
  background: ${props => props.tertiary ? tertiary : 'white'};
  color: ${props => props.tertiary ? 'white' : tertiary};

  text-decoration: none;
  display: inline-block;
  margin-bottom: 1em;

  &:hover {
    background: ${hover};
  }
  
  &:disabled {
    opacity: 0.4;
  }
`

export default Button
