import { useEffect, useState } from 'react'
import { getDatabase, off, onValue, ref, serverTimestamp, set } from 'firebase/database'
import { getAuth } from 'firebase/auth'

import _get from 'lodash.get'

const usePostUserScore = ({ isQuick = false, postId }) => {
  const uid = _get(getAuth(), 'currentUser.uid')
  const refPath = isQuick ? `quickVotes/${postId}/${uid}/score` : `votes/${postId}/${uid}/score`

  const [score, setScore] = useState()
  const [loading, setLoading] = useState(true)

  const db = getDatabase()

  let postScoreRef

  useEffect(() => {
    const get = () => {
      postScoreRef = ref(db, refPath)
      onValue(postScoreRef, (item) => {
        if (item.val()) {
          setScore(item.val())
        }
        setLoading(false)
      })
    }
    get()

    return () => {
      if (postScoreRef) { // close the connection
        off(postScoreRef)
      }
    }
  }, [postId])

  return {
    score,
    loading
  }
}

export default usePostUserScore
