const options = [
  {
    title: 'Top 10 worst movie directors',
    option1: 'Michael Bay'
  },
  {
    title: 'The most hated footbal team in the NFL',
    option1: 'New England Patriots'
  },
  {
    title: 'The best Batman',
    option1: 'Micahel Keaton'
  },
  {
    title: 'The best text editor is...',
    option1: 'Atom'
  },
  {
    title: 'The least talented pop musician...',
    option1: 'Cardi-B'
  },
  {
    title: 'The best decade for music was...',
    option1: '90\'s'
  }
]

export default () => options[Math.floor(Math.random() * options.length)]
