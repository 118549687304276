import React, { useEffect } from 'react'

import _get from 'lodash.get'
import { useTable, useSortBy } from 'react-table'

import styled from 'styled-components'
import LeaderBoard from './LeaderBoard'
import { tertiary } from '../variables'

import GAEvent from '../modules/analytics-event-helper'
import isImageUrl from '../modules/is-image-url'

import { GA } from '../constants'

import ImageModal from './ImageModal'

import usePostResults from '../hooks/usePostResults'
import usePostUserScore from '../hooks/usePostUserScore'

const columns = [{
  Header: 'Name',
  accessor: 'name',
  Cell: row => <div><span title={row.value}>{isImageUrl(row.value) && <ImageModal alt={row.value} src={row.value} height={100} />}{!isImageUrl(row.value) && <>{row.value}</>}</span></div>,
  defaultCanSort: false
}, {
  Header: 'Popular Rank',
  Cell: (row) => {
    return <div>{row.value}</div>
  },
  accessor: 'popRank',
  maxWidth: 126,
  sortType: 'number'
}, {
  id: 'yourRank',
  Header: 'Your Rank',
  accessor: d => d.yourRank === 'N/A' ? 'N/A' : d.yourRank + 1,
  maxWidth: 108,
  sortType: 'number'
}, {
  id: 'avg',
  Header: 'Avg Rank',
  accessor: d => `${d.avg}`,
  maxWidth: 112,
  sortType: 'number'
}, {
  id: 'mode',
  Header: 'Rank Mode',
  accessor: d => `${d.mode}`,
  maxWidth: 112,
  sortType: 'number'
}, {
  Header: 'No.1 Votes',
  accessor: 'no1s',
  maxWidth: 102,
  sortType: 'number'
}, {
  Header: 'Sortada Score™',
  accessor: 'score',
  maxWidth: 102,
  sortType: 'number'
}]

const ResultsBox = styled.div`
  box-sizing: border-box;
  overflow: hidden;
`

const StyledReactTable = styled.table`
  border: 0;
  width: 100%;
  td {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }
  thead {
    box-shadow: none !important;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;

    th {
      padding: 1em;
      text-align: left;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.8em;
      text-align: right;

      &:first-child {
        text-align: left;
      }

      &:focus {
        outline: none;
      }
    }
  }

  tbody {
    td {
      padding: 1em;
      border: 0;
    }
  }
`

const VictorsBox = styled.div`
  display: block;
  margin: 2em 0 2em;
  color: white;
  background-color: ${tertiary};
  padding: 1em;
  font-size: 1.3em;
`

const YourScore = styled.span`
  color: ${({ you, total }) => you / total > 0.70 ? 'green' : 'red'};
  font-weight: 800;
  font-size: 130%;
`

const ScoreRow = styled.div`
  margin-top: 0.8em;
`

const LabelText = styled.span`
  font-style: italic;
  text-transform: uppercase;
  font-size: 80%;
`

const ResultsTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy)
  return (
    <StyledReactTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr key={`header-group-tr-${idx}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, colIdx) => (
              <th key={`header-group-th-${colIdx}`} {...column.getSortByToggleProps()} {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr key={`body-row-${i}`} {...row.getRowProps()}>
              {row.cells.map((cell, cellIdx) => {
                return <td key={`body-cell-${cellIdx}`} {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </StyledReactTable>
  )
}

const Results = ({ postulation, vote, isQuick = false }) => {
  if (!vote && !postulation.isClosed && !postulation.isExpired) {
    return null
  }

  const { results, tally, loading: resultsLoading } = usePostResults({ post: postulation, vote })
  const { score, loading: scoreLoading } = usePostUserScore({ postId: postulation.id, isQuick })

  useEffect(() => {
    GAEvent({
      category: postulation.isQuick ? GA.CATEGORY.QUICK : GA.CATEGORY.POST,
      action: GA.ACTION.VIEW_RESULTS,
      label: postulation.id
    })
  }, [postulation.id])

  if (resultsLoading || scoreLoading) {
    return <div>Loading...</div>
  }

  const count = Object.keys(_get(results, 'totals', [])).length
  const scoreMax = (count * (count + 1)) / 2

  return (
    <ResultsBox>
      {results && results.winners &&
        <VictorsBox>
          {(results && results.winners) ? <LeaderBoard postId={postulation.id} /> : null}
          {score
            ? (
              <ScoreRow>
                <LabelText>Your score:</LabelText>
                <YourScore you={score} total={scoreMax}>{score}</YourScore>/{scoreMax}
              </ScoreRow>
              )
            : null}
          <div style={{ clear: 'both' }} />
        </VictorsBox>}
      <div style={{ overflow: 'scroll' }}>
        {tally && <ResultsTable data={tally} columns={columns} />}
      </div>
    </ResultsBox>
  )
}

export default Results
