import styled from 'styled-components'

export const SingleCenteredColumn = styled.div`
  margin: 0;
`

export default styled.div`
  padding: 0.8em;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column' };
  justify-content: ${({ justify }) => justify || 'flex-start' };
`
