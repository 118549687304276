import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import _map from 'lodash.map'

import useProfilePosts from '../hooks/useProfilePosts'

import { Button, ListLink } from '../components/styled'

const UserProfileTab = ({ view }) => {
  const { posts, showMore } = useProfilePosts({ view })

  return (
    <>
      <ul>
        {_map(posts, showPost => (
          <ListLink key={showPost.id}>
            <Link to={`/postulation/${showPost.id}`}> {showPost.title || showPost.postTitle}</Link>
          </ListLink>
        ))}
      </ul>
      <Button onClick={showMore} disabled={!showMore}>More {view}</Button>
    </>
  )
}

export default UserProfileTab
