import React, { useState } from 'react'
import styled from 'styled-components'

export const Modal = styled.div`
  display: black; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`

export const Content = styled.div`
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-height: ${({ height }) => height};
  padding: 30px;
`

const ImageModal = ({ img, closeFn }) => {
  const contentHeight = window.innerHeight * 0.85
  const imageHeight = window.innerHeight * 0.75
  const imageWidth = window.innerWidth * 0.75
  return (
    <Modal onClick={closeFn}>
      <Content height={contentHeight}>
        <center><img alt={img} src={img} style={{ maxHeight: imageHeight, maxWidth: imageWidth }} /></center>
      </Content>
    </Modal>
  )
}

const ModalableImage = ({ src, alt, height = 100 }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <img onClick={() => setShowModal(true)} alt={alt} src={src} height={height} />
      {showModal && <ImageModal img={src} closeFn={() => setShowModal(false)} />}
    </>
  )
}

export default ModalableImage
