import React from 'react'
import styled from 'styled-components'
import { Container } from './styled'

const LoadinvSvg = styled.svg`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const LoadingContainer = styled(Container)`
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  justify-content: center;
`

const Loading = ({ size = '100% '}) => (
  <LoadingContainer size={size}>
    <LoadinvSvg width={size} height={size} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' className='lds-wedges'><g transform='translate(50,50)'><g ng-attr-transform='scale({{config.scale}})' transform='scale(0.7)'><g transform='translate(-50,-50)'><g transform='rotate(342.859 50 50)'><animateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='1.675s' begin='0s' repeatCount='indefinite' /><path ng-attr-fill-opacity='{{config.opacity}}' ng-attr-fill='{{config.c1}}' d='M50 50L50 0A50 50 0 0 1 100 50Z' fillOpacity='0.8' fill='#50B557' /></g><g transform='rotate(77.1441 50 50)'><animateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='2.2333333333333334s' begin='0s' repeatCount='indefinite' /><path ng-attr-fill-opacity='{{config.opacity}}' ng-attr-fill='{{config.c2}}' d='M50 50L50 0A50 50 0 0 1 100 50Z' transform='rotate(90 50 50)' fillOpacity='0.8' fill='#2F83CE' /></g><g transform='rotate(171.429 50 50)'><animateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='3.35s' begin='0s' repeatCount='indefinite' /><path ng-attr-fill-opacity='{{config.opacity}}' ng-attr-fill='{{config.c3}}' d='M50 50L50 0A50 50 0 0 1 100 50Z' transform='rotate(180 50 50)' fillOpacity='0.8' fill='#D63432' /></g><g transform='rotate(265.715 50 50)'><animateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='6.7s' begin='0s' repeatCount='indefinite' /><path ng-attr-fill-opacity='{{config.opacity}}' ng-attr-fill='{{config.c4}}' d='M50 50L50 0A50 50 0 0 1 100 50Z' transform='rotate(270 50 50)' fillOpacity='0.8' fill='#FFFFFC' /></g></g></g></g></LoadinvSvg>
  </LoadingContainer>
)

export default Loading
