import styled from 'styled-components'

export default styled.div`
  margin-bottom: 2em;
  border-bottom: 1px solid #dadada;

  label {
    text-transform: uppercase;
    font-size: 0.8em;
    margin-right: 0.4em;
  }
`
