import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

import FirebaseInitializedApp from './firebase'

ReactGA.initialize('UA-130577990-1')

ReactDOM.render((
  <BrowserRouter>
    <FirebaseInitializedApp />
  </BrowserRouter>
), document.getElementById('root'))

// registerServiceWorker()
