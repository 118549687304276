import styled from 'styled-components'
import { tertiary } from '../../variables'

export const DraggableItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;
  color: ${tertiary};
  background: white;
  cursor: move;

  > span.number {
    font-size: 1.2em;
    margin-right: 0.4em;
    flex-basis: 24px;
    display: block;
    flex-shrink: 0;
    color: #777;
    font-family: sans-serif;
  }
`
export const DraggableContainer = styled.div`
  margin-bottom: 8px;
`
