export default (arr = []) => {
  if (!Array.isArray(arr)) {
    throw new Error('shuffle array expect an array as parameter.')
  }

  const collection = arr
  const rng = Math.random
  let len = arr.length
  let random
  let temp

  while (len) {
    random = Math.floor(rng() * len)
    len -= 1
    temp = collection[len]
    collection[len] = collection[random]
    collection[random] = temp
  }

  return collection
}
