import styled from 'styled-components'

export default styled.ol`
  list-style: unset;
  padding-left: 1.3em;

  li {
    font-size: 110%;
    margin-bottom: 0.9em;
  }
`
