import React, { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

// styled
import { Container, ProfileMenuContainer, TabButton } from '../components/styled'

// components
import ScoreHistory from '../components/ScoreHistory'
import MetaTags from '../components/MetaTags'
import UserProfileTab from './UserProfileTab'

// modules
import numberWithCommas from '../modules/number-with-commas'

// context
import { AuthConsumer } from '../auth/AuthContext'

const UserProfile = ({ history }) => {
  const [tab, setTab] = useState('owned')

  const changeFilter = (e) => {
    setTab(e)
    history.push(`/profile/${e}`)
  }

  return (
    <>
      <MetaTags title='Sortada.com: User Profile' />
      <AuthConsumer>
        {({ user, userName, userScore }) => (
          <Container className='App'>
            <h1>{userName} ({numberWithCommas(userScore)} pts.)</h1>
            <ProfileMenuContainer>
              <TabButton active={tab !== 'voted' && tab !== 'viewed' && tab !== 'score-history'} onClick={() => changeFilter('owned')}>Owned Posts</TabButton>
              <TabButton active={tab === 'voted'} onClick={() => changeFilter('voted')}>Voted Posts</TabButton>
              <TabButton active={tab === 'viewed'} onClick={() => changeFilter('viewed')}>Viewed Posts</TabButton>
              <TabButton active={tab === 'score-history'} onClick={() => changeFilter('score-history')}>Score History</TabButton>
            </ProfileMenuContainer>
            <Switch>
              <Route path='/profile/owned'><UserProfileTab view='owned' /></Route>
              <Route path='/profile/voted'><UserProfileTab view='voted' /></Route>
              <Route path='/profile/viewed'><UserProfileTab view='viewed' /></Route>
              <Route path='/profile/score-history'><ScoreHistory /></Route>
              <Route render={() => <Redirect to='/profile/owned' />} />
            </Switch>
            <br />
            <hr />
            <br />
          </Container>
        )}
      </AuthConsumer>
    </>
  )
}

export default UserProfile
