import { useEffect, useState } from 'react'
import { getDatabase, off, onValue, ref, remove, set } from 'firebase/database'
import _get from 'lodash.get'
import _forOwn from 'lodash.forown'

const getPostDescription = (post) => {
  let description = ''
  _forOwn(_get(post, 'candidates', {}), (candidate, key) => {
    description += `${candidate.name}? `
  })
  return description
}

const isClosed = (post) => {
  return post.isActive === false
}

const isExpired = (post) => {
  return post.expires !== -1 ? Date.now() > post.expires : false
}

const hasExpiration = (post) => {
  return post.expires && post.expires !== -1 && !isExpired(post) && !isClosed(post)
}

const usePost = ({ isQuick = false, postId }) => {
  const refPath = isQuick ? `quickPosts/${postId}` : `posts/${postId}`

  const [post, setPost] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)

  if (!postId) { // may be invoked on create post without postId
    return {
      post,
      error,
      loading: false
    }
  }

  const db = getDatabase()

  let postRef

  const closePost = () => {
    const closeRef = ref(db, `${refPath}/isActive`)
    set(closeRef, false)
    off(closeRef)
  }

  const deletePost = () => {
    return remove(ref(db, refPath))
  }

  useEffect(() => {
    const get = () => {
      postRef = ref(db, refPath)
      setLoading(true)
      onValue(postRef, (item) => {
        const post = item.val()
        if (post) {
          setPost({
            ...item.val(),
            id: postId,
            isClosed: isClosed(post),
            isExpired: isExpired(post),
            hasExpiration: hasExpiration(post),
            postDescription: getPostDescription(item)
          })
        } else {
          setError('Post not found')
        }
        setLoading(false)
      })
    }
    get()

    return () => {
      if (postRef) { // close the connection
        off(postRef)
      }
    }
  }, [postId])

  return {
    closePost,
    deletePost,
    post,
    error,
    loading
  }
}

export default usePost