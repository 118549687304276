import React, { useContext, useEffect } from 'react'

import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import _get from 'lodash.get'

// hooks
import useFeaturedPosts from '../hooks/useFeaturedPosts'

// styled 
import { RouterButton, Container, ListLink, SplitColumns, Text } from '../components/styled'

// components
import { AuthContext } from '../auth/AuthContext'
import MetaTags from '../components/MetaTags'

const Posts = () => {
  const { anonymous, user } = useContext(AuthContext)
  const { loading, posts } = useFeaturedPosts()

  useEffect(() => {
    window.previousLocation = _get(this, 'props.history.location.pathname')
    ReactGA.pageview('/posts')
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <MetaTags title='Sortada.com' />
      <Container className='App'>
        {user && !anonymous
          ? <RouterButton to='/add'>Add New Postulation</RouterButton>
          : <RouterButton to='/login'>Login To Create Your Own</RouterButton>}
        <SplitColumns>
          <div>
            <ul>
              {posts.map(post => (
                <ListLink key={`${post.title}-list-link`}>
                  <Link to={`/postulation/${post.id}`}>{post.title}</Link>
                </ListLink>
              ))}
            </ul>
          </div>
          <div>
            <h2>What is Sortada?</h2>
            <Text>Sortada is a tool that uses rank choice voting to answer questions. If you're new to Sortada, click on one of our public sortadas to vote and view the results. Note that you need to vote to see the results. Drag and drop the candidates into the order you desire and then click vote. Happy sorting.</Text>
            <Text>Head over to our <Link to='/about'>about page</Link> for more help.</Text>
          </div>
        </SplitColumns>
      </Container>
    </>
  )
}

export default Posts
