import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import _each from 'lodash.foreach'
import _set from 'lodash.set'
import _keys from 'lodash.keys'
// import _map from 'lodash.map'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FaGripLines } from 'react-icons/fa'


import shuffleArray from '../modules/shuffle-array'
import confirm from './confirmable'
import ImageModal from './ImageModal'

import GAEvent from '../modules/analytics-event-helper'
import { GA } from '../constants'
import isImageUrl from '../modules/is-image-url'

// styled
import { Button, DraggableItem, DraggableContainer } from './styled'

const convertCandidates = (candidates, myVote) => {
  return Object.keys(candidates)
    .map((key, i) => {
      const returnObj = { id: key, name: candidates[key].name, media: candidates[key].media }
      if (myVote !== null && myVote !== undefined) {
        _set(returnObj, 'id', myVote.ranks[key].idx)
      }
      return returnObj
    })
    .sort((a, b) => a.id - b.id)
}

const DraggableListContainer = styled(DraggableContainer)``

const DraggableWrap = styled.div`
`

const ParentWrapper = styled.div`
  div {
    div {
      border-bottom: 1px dashed #50B557;
    }
    &:last-child {
      border-bottom: 0px;
    }
  }
`

const StyledDraggableItem = styled(DraggableItem)`
  @media(min-width: 700px) {
    padding: 1em;
  }

  .grippy {
      content: '....';
      width: 20px;
      height: 22px;
      display: inline-block;
      overflow: hidden;
      line-height: 5px;
      cursor: move;
      vertical-align: middle;
      font-size: 12px;
      font-family: sans-serif;
      letter-spacing: 2px;
      color: #cccccc;
      text-shadow: 1px 0 1px black;
      flex-shrink: 0;
      margin-right: 0;
      margin-left: 0.5em;
    }

    .grippy:after {
      content: '.... .... .... ....';
    }

  &:hover {
    background-color: #f5f5f5;
    border-color: #eaeaea;
  }

  .number {
    padding-left: 15px;
  }
`

const Vote = ({ postulation, vote, isQuick, saveVote, abstain }) => {
  const [saved, setSaved] = useState(vote && vote.ranks)
  const [didMove, setDidMove] = useState(false)
  const [userRanks, setUserRanks] = useState(shuffleArray(convertCandidates(postulation.candidates, vote)))

  useEffect(() => {
    GAEvent({
      category: isQuick ? GA.CATEGORY.QUICK : GA.CATEGORY.POST,
      action: GA.ACTION.VIEW_VOTING,
      label: postulation.id
    })
  }, [])

  const handleDragEnd = (drop) => {
    if (drop.reason === 'DROP' && drop.destination) {
      GAEvent({
        category: isQuick ? GA.CATEGORY.QUICK : GA.CATEGORY.POST,
        action: GA.ACTION.SORT,
        label: postulation.id
      })

      const current = userRanks
      const move = current.splice(drop.source.index, 1)
      current.splice(drop.destination.index, 0, move[0])
      setUserRanks(current)
      setDidMove(true)
    }
  }

  const doSave = () => {
    saveVote(userRanks).then(() => {
      GAEvent({
        category: isQuick ? GA.CATEGORY.QUICK : GA.CATEGORY.POST,
        action: GA.ACTION.VOTED,
        label: postulation.id
      })
      setSaved(true)
      // refreshResults()
    }).catch((failed) => {
      console.log('VOTE FAILED', failed)
    })
  }

  const handleSaveCheck = () => {
    if (didMove === false) {
      confirm('You didn\'t make any changes to the candidates.  Are you sure you want to submit?').then(
        (result) => {
          doSave()
        },
        (result) => {

        }
      )
    } else {
      doSave()
    }
  }

  const handleClickAbstain = () => {
    confirm('If you abstain you can view the results but will never be able to participate in this sortada.  You cool with that?').then(
      (result) => {
        abstain(userRanks).then((success) => {
          GAEvent({
            category: isQuick ? GA.CATEGORY.QUICK : GA.CATEGORY.POST,
            action: GA.ACTION.VOTED,
            label: postulation.id
          })
          setSaved(true)
        }).catch((failed) => {
          console.log('ABSTAIN VOTE FAILED', failed)
        })
      },
      (result) => {

      }
    )
  }

  if (!userRanks) {
    return null
  }

  return (
    <ParentWrapper>
      <DragDropContext onDragEnd={handleDragEnd}>
        <DraggableListContainer className='Vote'>
          <Droppable droppableId='droppable1'>
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                {userRanks.map((x, i) => (
                  <Draggable className='draggable-guy' key={x.id} draggableId={x.id} index={i} isDragDisabled={saved}>
                    {(provided, snapshot) => (
                      <DraggableWrap>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <StyledDraggableItem key={`candidate-${i}`}>
                            <FaGripLines size={30} /><span className='number'>{i + 1}.</span>{isImageUrl(x.name) && <ImageModal alt={x.name} src={x.name} height={150} />}{!isImageUrl(x.name) && <>{x.name}</>}
                          </StyledDraggableItem>
                        </div>
                        {provided.placeholder}
                      </DraggableWrap>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DraggableListContainer>
      </DragDropContext>
      <br />
      <Button primary type='button' onClick={handleSaveCheck} disabled={saved}>Save My Vote</Button>
      <Button primary type='button' onClick={handleClickAbstain} disabled={saved}>Abstain!</Button>
    </ParentWrapper>
  )
}

export default Vote
