import { useEffect, useState } from 'react'
import { getAuth } from 'firebase/auth'
import { getDatabase, off, ref, query, orderByChild, equalTo, limitToFirst, onValue } from 'firebase/database'

const getRefPath = ({ view, uid }) => {
  switch (view) {
    case 'owned': return 'posts'
    case 'voted': return `userActivity/${uid}/votedPosts`
    case 'viewed': return `userActivity/${uid}/viewedPosts`
    default: return 'posts'
  }
}

const getQuery = ({ view, ref, limit, uid }) => {
  switch (view) {
    case 'owned': return query(ref, orderByChild('ownerId'), equalTo(uid))
    case 'voted': return query(ref, orderByChild('timestamp'), limitToFirst(limit))
    case 'viewed': return query(ref, orderByChild('timestamp'), limitToFirst(limit))
    default: return query(ref, orderByChild('ownerId'), equalTo(uid))
  }
}

const useProfilePosts = ({ view, limit: initialLimit = 10 }) => {
  const db = getDatabase()
  const uid = getAuth().currentUser.uid

  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState([])
  const [limit, setLimit] = useState(initialLimit)
  const [hasMore, setHasMore] = useState(true)
  const [previousCount, setPreviousCount] = useState(0)

  let postsRef

  const showMore = () => {
    setLimit(limit + 10)
  }

  useEffect(() => {
    const update = async () => {
      if (postsRef) {
        off(postsRef)
      }

      postsRef = ref(db, getRefPath({ view, uid }))
      onValue(getQuery({ view, ref: postsRef, limit, uid }), (response) => {
        const data = response.val()
        const convertedPosts = []
        if (data) {
          for (const [key, value] of Object.entries(data)) {
            convertedPosts.push({
              ...value,
              id: key
            })
          }
        }

        if (convertedPosts.length === previousCount || convertedPosts.length < limit) {
          setHasMore(false)
        }
        setPreviousCount(convertedPosts.length)
        setPosts(convertedPosts)
        setLoading(false)
      })
    }

    update()

    return () => {
      if (postsRef) {
        off(postsRef)
      }
    }
  }, [limit])

  return {
    posts,
    loading,
    showMore: hasMore ? showMore : undefined
  }
}

export default useProfilePosts
