import React, { useState } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Container } from './styled'
import ClipIcon from '../assets/clip-icon.png'

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon
//   EmailShareButton,
//   EmailIcon
} from 'react-share'

const ClipContainer = styled.div`
  padding: 4px;
  height: 24px;
  width: 24px;
  background: #8EFBFE;
  transition: transform 1s linear;
  transform-origin: center center;
  transform-style: preserve-3D;
`

const Copy = styled.div`
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
`

const ShareContainer = styled(Container)`
  margin-top: 50px;
  align-items: left;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  justify-content: left;
  cursor: 'pointer';
`

const Share = ({ title }) => {
  const [copied, setCopied] = useState(false)

  const rotateStyle = {
    transform: 'rotate(360deg)'
  }

  const applyRotate = copied ? rotateStyle : {}
  const url = window.location.href

  const handleCopied = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 500)
  }

  return (
    <ShareContainer>
      <div style={{ cursor: 'pointer' }}>
        <FacebookShareButton url={url}>
          <FacebookIcon size={32} round={false} />
        </FacebookShareButton>
      </div>
      <div style={{ cursor: 'pointer' }}>
        <TwitterShareButton url={url} title={title} via='sortada12345' hashtags={['sortada']}>
          <TwitterIcon size={32} round={false} />
        </TwitterShareButton>
      </div>
      <div style={{ cursor: 'pointer' }}>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={32} round={false} />
        </LinkedinShareButton>
      </div>
      <CopyToClipboard text={url} onCopy={handleCopied}>
        <ClipContainer style={applyRotate} title='Copy URL to Clipboard'>
          <img style={{ cursor: 'pointer' }} alt='Copy URL to Clipboard' src={ClipIcon} height={24} width={24} />
        </ClipContainer>
      </CopyToClipboard>
      {/* <div>
            <EmailShareButton subject={title} body={description}>
                <EmailIcon size={32} round={false} />
            </EmailShareButton>
            </div> */}
      <Copy>
        Share this sortada!
      </Copy>
    </ShareContainer>
  )
}

export default Share
