import React from 'react'

import useScoreHistory from '../hooks/useScoreHistory'

import { differenceInDays, differenceInSeconds, differenceInMinutes, differenceInHours } from 'date-fns'
import _get from 'lodash.get'
import _values from 'lodash.values'
import _map from 'lodash.map'
import styled from 'styled-components'

import { Link } from 'react-router-dom'

const positiveActivityShades = [
  {
    background: '#EFF8EF',
    text: '#222'
  },
  {
    background: '#DFF1E0',
    text: '#222'
  },
  {
    background: '#CFEAD1',
    text: '#222'
  },
  {
    background: '#BFE4C1',
    text: '#222'
  },
  {
    background: '#AFDDB2',
    text: '#222'
  },
  {
    background: '#9FD6A3',
    text: '#222'
  },
  {
    background: '#8FCF94',
    text: '#222'
  },
  {
    background: '#7FC984',
    text: 'white'
  },
  {
    background: '#6FC275',
    text: 'white'
  }
]

const negativeActivityShades = [
  {
    background: '#FDEBEF',
    text: '#222'
  },
  {
    background: '#FCD8E0',
    text: '#222'
  },
  {
    background: '#FAC5D0',
    text: '#222'
  },
  {
    background: '#F9B2C1',
    text: '#222'
  },
  {
    background: '#F79FB2',
    text: '#222'
  },
  {
    background: '#F68CA2',
    text: '#222'
  },
  {
    background: '#F47993',
    text: '#222'
  },
  {
    background: '#F36684',
    text: 'white'
  },
  {
    background: '#F15374',
    text: 'white'
  }
]

const getActivityShade = (score, positive, negative) => {
  let color
  if (score > 0) {
    if (score > positive.length) {
      color = positive[positive.length - 1]
    } else {
      color = positive[score - 1]
    }
  } else {
    const positiveScore = Math.abs(score)
    if (positiveScore > negative.length) {
      color = negative[negative.length - 1]
    } else {
      color = negative[positiveScore - 1]
    }
  }

  return color
}

const getDifferenceText = (recordTs) => {
  const now = new Date()
  const record = new Date(recordTs * -1)

  switch (true) {
    case differenceInSeconds(now, record) <= 119:
      return `${differenceInSeconds(now, record)} seconds ago`
    case differenceInMinutes(now, record) <= 119:
      return `${differenceInMinutes(now, record)} minutes ago`
    case differenceInHours(now, record) <= 47:
      return `${differenceInHours(now, record)} hours ago`
    default:
      return `${differenceInDays(now, record)} days ago`
  }
}

const ActivityItem = styled(Link)`
  display: block;
  padding: 0.5em 0.5em 0.5em 0;
  color: #222;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const ScoreBox = styled.span`
  background: ${props => _get(getActivityShade(props.score, positiveActivityShades, negativeActivityShades), 'background')};
  color: ${props => _get(getActivityShade(props.score, positiveActivityShades, negativeActivityShades), 'text')};
  display: inline-block;
  margin-right: 1em;
  min-width: 26px;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.3em;
  text-align: center;
`

const TimeBox = styled.span`
  display: inline-block;
  color: #5a5a5a;
  font-size: 0.6em;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-left: 1em;
`

const ScoreHistory = () => {
  const { scoreHistory } = useScoreHistory()

  return (
    <>
      {_map(scoreHistory, historyRecord =>
        <ActivityItem to={`/postulation/${historyRecord.postId}`} key={historyRecord.timestamp} score={historyRecord.scoreChange}>
          <ScoreBox score={historyRecord.scoreChange}>{historyRecord.scoreChange > 0 ? `+${historyRecord.scoreChange}` : historyRecord.scoreChange}</ScoreBox>
          {historyRecord.postTitle}
          <TimeBox>{getDifferenceText(historyRecord.timestamp)}</TimeBox>
        </ActivityItem>)}
    </>
  )
}

export default ScoreHistory
