import styled from 'styled-components'

export default styled.button`
  display: inline-block;
  padding: 1.2em;
  text-align: center;
  border: 1px solid #dadada;
  border-left: 0;
  border-bottom: 0;
  background-color: ${props => props.active ? '#ededed' : 'none'};
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  &:first-child {
    border-left: 1px solid #dadada;
  }
`
