import { useEffect, useState } from 'react'
import { getDatabase, off, onValue, ref, remove } from 'firebase/database'
import _get from 'lodash.get'
import _forOwn from 'lodash.forown'

/**
 * Order the results by total, lowest to highest
 *    - score
 *    - voteMatch?  - calc after sorting
 *    - percentAgree?
 *    - most#1Votes?
 */
const getTally = (candidates, results, vote) => {
  if (!candidates || !results) {
    return []
  }

  const sorted = Object.keys(results.totals).map((voteKey) => {
    return {
      score: results.totals[voteKey],
      avg: results.avg ? results.avg[voteKey] : '-',
      mode: results.mode ? results.mode[voteKey].join('/') : '-',
      name: candidates[voteKey].name,
      ownerRank: candidates[voteKey].idx,
      yourRank: vote && !vote.abstained ? vote.ranks[voteKey].idx : 'N/A',
      id: voteKey
    }
  }).sort((a, b) => { return a.score - b.score })

  const byScore = sorted.map((row, i) => {
    return {
      ...row,
      popRank: i + 1,
      voteMatch: vote ? (i === vote.ranks[row.id].idx) : false, // my vote idx = this idx (i)
      percentAgree: Math.floor((_get(results, `percent.${row.id}.${i}`, 0) / results.voteCount) * 100),
      no1s: _get(results, `percent.${row.id}.0`, 0)
    }
  })

  return byScore
}
  
const usePostResults = ({ post, vote }) => {
  const refPath = `results/${post.id}`

  const [results, setResults] = useState()
  const [loading, setLoading] = useState(true)

  const db = getDatabase()

  let resultsRef

  useEffect(() => {
    const get = () => {
      resultsRef = ref(db, refPath)
      onValue(resultsRef, (item) => {
        if (item.val()) {
          setResults({
            ...item.val(),
            id: post.id,
          })
        }
        setLoading(false)
      })
    }
    get()

    return () => {
      if (resultsRef) { // close the connection
        off(resultsRef)
      }
    }
  }, [post.id, vote])

  return {
    results,
    tally: getTally(post.candidates, results, vote),
    loading
  }
}

export default usePostResults