import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Mention } from 'react-twitter-widgets'
import ReactGA from 'react-ga'
import { Container } from '../components/styled'
import MetaTags from '../components/MetaTags'

const Text = styled.p`
  margin-bottom: 1em;
`

export default () => {
  ReactGA.pageview('/contact')

  return (
    <Container>
      <MetaTags title='Sortada.com: Contact Us' />
      <h2>Contact Us</h2>
      <Text>If you're looking for help, take a gander at our <Link to='/about'>about page</Link> for a little more help.</Text>
      <Text>If you must contact us...contact us on Twitter!</Text>
      <Mention username='sortada12345' />
    </Container>
  )
}
