import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { slide as Menu } from 'react-burger-menu'

import { AuthContext } from '../auth/AuthContext'

import { NavBarTitle } from './styled'
import { penny, tertiary } from '../variables'
import numberWithCommas from '../modules/number-with-commas'

import Logo from '../assets/apple-icon-120x120.png'
import Anon from '../assets/anon.png'

const styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '26px',
    height: '28px',
    right: '26px',
    top: '19px'
  },
  bmBurgerBars: {
    background: tertiary
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    background: 'transparent'
  },
  bmCross: {
    background: penny
  },
  bmMenu: {
    background: tertiary,
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: penny
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'block',
    color: 'white',
    textDecoration: 'none',
    marginBottom: '8px'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    left: 0
  },
  bmMenuWrap: {
    top: 0,
    height: '100vh'
  }
}

const StyledMenu = styled(Menu)`
  hr {
    border: 0;
    border-top: 1px solid #9FD6A3;
    display: block;
    height: 1px;
  }
`

const avatarStyle = {
  maxHeight: '48px',
  borderRadius: '50%'
}

const noAvatarStyle = {
  maxHeight: '48px'
}

const HeaderComp = styled.header`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  padding: 0.8em;
`

const AvatarLink = styled(Link)`
  display: block;
  height: 48px;
  position: relative;
  width: 48px;
`
const AvatarDiv = styled.div`
  z-index: 1000;
  position: absolute;
  width: 26px;
  height: 28px;
  right: 97px;
  top: 9px;
`
const Score = styled.span`
    background: ${tertiary};
    border-radius: 20px;
    color: white;
    font-size: 0.8em;
    font-weight: bold;
    padding: 0.25em 0.3em 0.45em;
    position: absolute;
    left: -15px;
    text-align: center;
`
const SubTitle = styled.span`
  font-size: 0.5em;

  @media(max-width: 699px) {
    display: none;
  }
`

const MenuLink = styled(Link)`
  font-size: 1.2em;
  display: block;
  margin-bottom: 0, 1.2em;
  padding: 0.8em 0;
  border-bottom: 1px solid #fff;
  text-decoration: none;

  &:visited {
    color: white;
  }
`

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { user, anonymous, userScore, logout } = useContext(AuthContext)
  const handleMenuClick = (e) => {
    setMenuOpen(false)
  }

  return (
    <HeaderComp className='App-header'>
      <Link to='/'><img style={noAvatarStyle} src={Logo} className='App-logo' alt='logo' /></Link>
      <NavBarTitle>
        Sortada
        <SubTitle>(beta): a place to sort it all out</SubTitle>
      </NavBarTitle>
      {user && !anonymous && (
        <AvatarDiv className='user-profile'>
          <AvatarLink to='/profile/voted'><img style={avatarStyle} className='avatar' alt='my dashboard' src={user.photoURL || Anon} />
            <Score>{numberWithCommas(userScore)}</Score>
          </AvatarLink>
        </AvatarDiv>)}
      <StyledMenu isOpen={menuOpen} right styles={styles}>
        <MenuLink onClick={handleMenuClick} id='home' className='menu-item' to='/'>Home</MenuLink>
        {user && !anonymous && <MenuLink onClick={handleMenuClick} id='profile' className='menu-item' to='/profile'>Profile</MenuLink>}
        <MenuLink onClick={handleMenuClick} id='contact' className='menu-item' to='/contact'>Contact</MenuLink>
        <MenuLink onClick={handleMenuClick} id='about' className='menu-item' to='/about'>About</MenuLink>
        {user && !anonymous && (<><MenuLink to='/add'>+ New Postulation</MenuLink></>)}
        {user && !anonymous && <MenuLink onClick={e => { handleMenuClick(e); logout() }} id='logout' to='/' className='menu-item'>Logout</MenuLink>}
        {(!user || anonymous) && <MenuLink onClick={handleMenuClick} id='emailLogin' className='menu-item' to='/login'>Login</MenuLink>}
      </StyledMenu>
    </HeaderComp>
  )
}

export default Header
