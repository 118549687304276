import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { tertiary } from '../variables'
import Loading from './Loading'
import { Container } from './styled'

const ImageContainer = styled(Container)`
  border: 3px solid ${tertiary};
  margin: 10px;
`

let to

const PostImage = ({ url }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    if (!url) {
      setLoading(true)
      clearTimeout(to)
      to = setTimeout(() => {
        setError('No Image Found')
        setLoading(false)
      }, 10000)
    } else {
      clearTimeout(to)
      setLoading(false)
    }
  }, [url])


  if (loading) {
    return <Loading size="250px"/>
  }

  if (error) {
    return <ImageContainer>
      No Image Found
    </ImageContainer>
  }

  if (url.indexOf('ms-icon') > -1) {
    return <ImageContainer>
      <p>You must have postulated something naughty.</p>
    </ImageContainer> 
  }

  return <ImageContainer>
    <img src={url} height="250" width="250" />
  </ImageContainer>
}

export default PostImage