import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Mention } from 'react-twitter-widgets'
import ReactGA from 'react-ga'
import { Container, Text } from '../components/styled'
import MetaTags from '../components/MetaTags'

const AText = styled(Text)`
  font-size: 1.3em;
`

export default () => {
  ReactGA.pageview('/about')

  return (
    <Container>
      <MetaTags title='Sortada.com: About' />
      <h1>What is Sortada?</h1>
      <AText>Sortada was initially created for one single reason: to find the worst Adam Sandler movie.</AText>
      <AText>Opinions on the topic varied wildly, and a difinitive answer seemed impossible. We needed a tool that accounted for more than everyone's first choice. Just accounting for everyone's number one choice leaves a lot of people unhappy with the decision. Sortada is built to look at everyone's full spectrum of opinions, and choose a winner that more people agree with.</AText>
      <h2>How do I use it?</h2>
      <AText>Sortada's are private by default. When you create a Sortada it won't be listed anywhere, so the only way anyone will find it is if you share the link with them. We do maintain a list of <Link to='/'>public sortadas</Link> on the home page. Feel free to go vote on those to get a hang of it (and score some points). Each sortada has a winner. The winner is the person who's rankings most accurately reflect the group consensus order. Once you vote on a Sortada you will see the results page. The results table has 5 columns: Name, Popular Rank, Your Rank, Agreement, and Number 1 votes. The Popular Rank column represents the group's voting results. Your Rank reminds you how you voted on the sortada, Agreement tells you what percentage of people who voted ranked that item at its popular rank order, and No 1 Votes shows you how many people ranked that item first overall.</AText>
      <AText>You can order the results table by any column in ascending or descending order by clicking on a heading. You can also drag the columns larger or smaller.</AText>
      <AText>If you head to your profile page (available in the main menu) you will see a list of every sortada you have created (own), have voted on (voted), and have viewed (viewed). Below that list is your activity log. You gain points each time you vote on a sortada, and the activity log gives you an itemized look at your total score. Keep in mind that you can gain or lose points when someone votes on a sortada that you have voted on, and their vote changes the Popular Rank.</AText>
      <AText>We have greatly enjoyed playing around with Sortada since it's creation, and we hope you do too. Drop us a line on twitter at with any comments, suggestions, praise, or complaints. Happy sorting.</AText>
      <Mention username='sortada12345' />
    </Container>
  )
}
