import React from 'react'
import styled from 'styled-components'

import _each from 'lodash.foreach'
import _keys from 'lodash.keys'
import _map from 'lodash.map'

// hooks
import useLeaderboardScores from '../hooks/useLeaderboardScores'

import Cup from '../assets/champion.svg'

const VoteCount = styled.div`
  font-style: italic;
  text-transform: uppercase;
  font-size: 80%;
`

const WinnerBox = styled.div`
  img {
    margin-right: 0.4em;
  }

  strong {
    font-weight: 800;
  }
`

const FirstSpan = styled.span`
  display: inline-block;
  width: 50px;
`

const PointsSpan = styled.span`
  display: inline-block;
  width: 50px;
`

const UsersSpan = styled.span`
  display: inline-block;
`

const LeaderList = styled.ul`
  list-style-type: none;
  li {
    padding-bottom: 5px;
    font-size: 0.8em;
  }
  li:first-child {
    padding-bottom: 10px;
    font-size: 1.5em;
  }
`

const LeaderBoard = ({ postId }) => {
  const { leaders, voteCount, loading, error } = useLeaderboardScores({ postId })
  if (loading) {
    return null
  }
  if (error) {
    return <div>{error}</div>
  }
  return (
    <WinnerBox>
      <div style={{ width: '75%', float: 'left', minWidth: '495px' }}>
        <LeaderList>
          {
            _map(leaders, (leaderRecord, idx) => {
              return (
                <li key={`${leaderRecord.score}_leader`}>
                  <FirstSpan>{idx === 0 ? <img alt='championship-trophy-icon' src={Cup} /> : null}</FirstSpan>
                  <PointsSpan>{leaderRecord.score === '0' ? <span title='Abstained from ranking'>Abs.</span> : leaderRecord.score}</PointsSpan>
                  <UsersSpan>{leaderRecord.users.join(', ')}</UsersSpan>
                </li>
              )
            })
          }
        </LeaderList>
      </div>
      {voteCount && <VoteCount>Votes: {voteCount}</VoteCount>}
    </WinnerBox>
  )
}

export default LeaderBoard
