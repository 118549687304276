import React, { useEffect } from 'react'
import { Formik, FieldArray } from 'formik'

import ReactGA from 'react-ga'
import * as Yup from 'yup'

import styled from 'styled-components'
import _map from 'lodash.map'

import { GA } from '../constants'
import GAEvent from '../modules/analytics-event-helper'


import { Button, Container, FieldError, Input as InputContainer, InfoBox, Select as SelectInput, SingleCenteredColumn } from '../components/styled'

import InfoTooltip from '../components/InfoTooltip'
import Switch from 'react-switch'

import isImageUrl from '../modules/is-image-url'
import getRandomPlaceholder from '../modules/random-post-placeholder'

import { tertiary } from '../variables'
import ImageModal from '../components/ImageModal'

import usePost from '../hooks/usePost'
import createPost from '../utils/create-post'

const CreatePostSchema = Yup.object().shape({
  title: Yup.string()
    .min(6, 'Postulation must be at least 6 characters.')
    .max(150, 'Postulation must be less than 150 characters.')
    .required('Postulation title is required.'),
  candidates: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .min(1)
          .max(1024)
          .label('Candidate')
          .required('Candidate cannot be blank.')
      })
    )
    .test({
      message: 'Postulation requires at least 2 candidates.',
      test: arr => arr && arr.length > 1
    })
})

const quickOptions = [
  { value: '300000', text: '5 minutes' },
  { value: '900000', text: '15 minutes' },
  { value: '1800000', text: '30 minutes' },
  { value: '3600000', text: '1 hour' }
]

const allOptions = [
  { value: '-1', text: 'Never' },
  ...quickOptions,
  { value: '7200000', text: '2 hours' },
  { value: '14400000', text: '4 hours' },
  { value: '86400000', text: '1 day' },
  { value: '604800000', text: '1 week' },
  { value: '2592000000', text: '1 month' }
]

const DeleteButton = styled.button`
  background: none;
  border: 0;
  color: #999;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 20px;
  font-size: 1.8em;
  padding: 0;
  padding-left: 5px;
`

const InputColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TextInput = styled.input`
  font-size: '1.2em',
  padding: '0.4em',
  background: 'white',
  border: 1px solid ${tertiary}
`

const rowFill = {
  display: 'flex',
  flexWrap: 'wrap'
}
const input = {
  marginRight: '15px'
}

const handleSavePostulation = async (values, history) => {
  const redirectUrl = values.isQuick ? 'quick-post' : 'postulation'

  createPost(values).then(({ newKey }) => {
    GAEvent({
      category: values.isQuick ? GA.CATEGORY.QUICK : GA.CATEGORY.POST,
      action: GA.ACTION.CREATED,
      label: newKey
    })

    history.push(`/${redirectUrl}/${newKey}`)
  }).catch((failed) => {
    window.alert(failed)
  })
}

const revertCandidates = (candidatesFromDb) => {
  const candidateArray = []
  for (const [, value] of Object.entries(candidatesFromDb)) {
    candidateArray.push({
      name: value.name,
      media: value.media || ''
    })
  }
  return candidateArray
}

const CreatePost = ({ history, match }) => {
  const { title: titlePlaceholder, option1: option1Placeholder } = getRandomPlaceholder()
  const { post: fromPost, loading: fromPostLoading } = usePost({ postId: match.params.sourceId })

  const defaultFormData = fromPost ? {
    title: fromPost.title,
    candidates: revertCandidates(fromPost.candidates),
    sourceId: fromPost.id
  } : {}

  useEffect(() => {
    if (match.params.sourceId) {
      ReactGA.pageview('/new/re-tada')
    } else {
      ReactGA.pageview('/new')
    }
  }, [match.params.sourceId])

  function focusLastCandidate(values) {
    setTimeout(() => {
      try {
        document.getElementById(`candidates.${values.candidates.length}.name`).focus()
      } catch (e) {
        console.log(e)
      }
    }, 100)
  }

  const handleCandidateKeyUp = (e, pushFn, values) => {
    if (e.keyCode === 13) {
      // console.log(e)
      e.preventDefault()
      e.stopPropagation()
      pushFn({ name: '', media: '' })
      focusLastCandidate(values)
    }
  }

  return (
    <Container className='App'>
      <SingleCenteredColumn>
        <InfoBox>Have no fear! This sortada is private and will not be on the home page. Share it with friends by giving them the link after clicking Save. </InfoBox>
        <Formik
          enableReinitialize
          initialValues={{
            title: '',
            candidates: [
              { name: '', media: '' }
            ],
            isQuick: false,
            postExpires: '-1',
            quickDuration: '900000',
            sourceId: 'xxx',
            ...defaultFormData
          }}
          validationSchema={CreatePostSchema}
          onSubmit={(values) => handleSavePostulation(values, history)}
          render={({ dirty, isValid, values, errors, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
            <>
              <InputContainer>
                  <label htmlFor='title'>Postulation</label>
                  <TextInput
                    type='text'
                    id='title'
                    name='title'
                    value={values.title}
                    placeholder={titlePlaceholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                    {errors.title && <FieldError>{errors.title}</FieldError>}
              </InputContainer>
              <FieldArray
                name='candidates'
                render={({ remove, push }) => (
                  <>
                    {_map(values.candidates, (candiate, idx) => {
                      return (
                        <InputContainer direction='row' key={`candidates.${idx}`}>
                          <InputContainer direction='column'>
                            <label htmlFor={`candidates.${idx}.name`}>Option {idx + 1}</label>
                            <TextInput
                              type='text'
                              id={`candidates.${idx}.name`}
                              name={`candidates.${idx}.name`}
                              value={values.candidates[idx].name}
                              placeholder={idx === 0 ? option1Placeholder : ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onKeyUp={(e) => handleCandidateKeyUp(e, push, values)}
                            />
                            {errors.candidates && errors.candidates[idx] && errors.candidates[idx].name && <FieldError>{errors.candidates[idx].name}</FieldError>}
                            {isImageUrl(values.candidates[idx].name) && <ImageModal alt={values.candidates[idx].name} src={values.candidates[idx].name} height={150} />}
                          </InputContainer>
                          <div>
                           {values.candidates.length > 1 && <DeleteButton id={`delete.${idx}`} alert title='Remove Candidate' onClick={() => remove(idx)}>&#9760;</DeleteButton>}
                          </div>
                        </InputContainer>
                      )
                    })}
                    <Button type='button' onClick={() => { push({ name: '', media: '' }); focusLastCandidate(values) }}>Add Candidate</Button>
                  </>
                )}
              />
              <InputContainer key='quickInput' style={rowFill}>
                <div style={input}>
                  <label htmlFor='isQuick'>Quick Post</label>
                  <Switch
                    onChange={e => setFieldValue('isQuick', e)}
                    checked={values.isQuick}
                    id='isQuick'
                  />
                  <InfoTooltip text='Quick posts automatically close after 15, 30 or 60 minutes and can be voted on anonymously.' />
                </div>
              </InputContainer>
              <InputContainer key='postExpiresInput' style={rowFill}>
                <div style={input}>
                  <label htmlFor='postExpires'>Post Expires</label>
                  {values.isQuick
                    ? (
                      <>
                        <SelectInput value={values.quickDuration} disabled={!values.isQuick} name='quickDuration' onChange={handleChange}>
                          {_map(quickOptions, (option) =>
                            <option key={`${option.value}-quick-post-duration`} value={option.value}>{option.text}</option>)}
                        </SelectInput>
                        <InfoTooltip text='Quick posts require an expiration of an hour or less' />
                      </>
                      )
                    : (
                      <InputRow>
                        <SelectInput value={values.postExpires} disabled={values.isQuick} name='postExpires' onChange={handleChange}>
                          {_map(allOptions, (option) => <option key={`${option.value}-post-expires`} value={option.value}>{option.text}</option>)}
                        </SelectInput>
                        <InfoTooltip text='Shut down voting on this post automatically by selecting an expiration time period' />
                      </InputRow>
                      )}
                </div>
              </InputContainer>
              {typeof errors.candidates === 'string' && <FieldError>{errors.candidates}</FieldError>}
              <Button disabled={!dirty || !isValid} primary type='button' onClick={handleSubmit}>Save</Button>
            </>
          )}
        />
      </SingleCenteredColumn>
    </Container>
  )
}

export default CreatePost
