import React from 'react'
import ReactTooltip from 'react-tooltip'
import { v4 as uuidV4 } from 'uuid'
import _each from 'lodash.foreach'
import styled from 'styled-components'

import infoIcon from '../assets/info_icon.png'

const Icon = styled.img`
  cursor: pointer;
  height: 25px;
  width: 25px;
  padding-left: 10px;
`

const breakPhrase = (text, maxLineChars) => {
  let total = 0
  const words = text.split(' ')

  let broken = ''

  _each(words, word => {
    total = total + word.length
    broken = broken + word + ' '
    if (total >= maxLineChars) {
      broken = broken + '<br />'
      total = 0
    }
  })

  return broken
}

export default ({ text, maxChars = 50, id = uuidV4() }) => ( // eslint-disable-line
  <>
    <Icon data-for={id} data-tip={breakPhrase(text, maxChars)} src={infoIcon} />
    <ReactTooltip id={id} multiline />
  </>
)
