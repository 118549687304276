import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Countdown from 'react-countdown-now'

import _forOwn from 'lodash.forown'
import _get from 'lodash.get'
import _map from 'lodash.map'

import ReactGA from 'react-ga'
import { GA } from '../constants'

import GAEvent from '../modules/analytics-event-helper'

// hooks
import usePost from '../hooks/usePost'
import usePostUserVote from '../hooks/usePostUserVote'
import useSpawnedPosts from '../hooks/useSpawnedPosts'

// styled
import { Button, Container, InfoBox, PublicList, TimerContainer } from '../components/styled'

// components
import Vote from '../components/Vote'
import Results from '../components/Results'
import Spawned from '../components/SpawnedRetadas'
import { AuthContext } from '../auth/AuthContext'
import MetaTags from '../components/MetaTags'
import Share from '../components/Share'
import PostImage from'../components/PostImage'

// utils
import userViewedPost from '../utils/user-viewed-post'

const deleteStyle = {
  marginTop: '30px'
}

const PublicCandidatesList = ({ postulation }) => {
  useEffect(() => {
    GAEvent({
      category: GA.CATEGORY.POST,
      action: GA.ACTION.VIEW_PUBLIC,
      label: postulation.id
    })
  }, [])

  return (
    <>
      <PublicList>
        {_map(postulation.candidates, candidate => (
          <li key={`${candidate.idx}`}>{candidate.name}</li>
        ))}
      </PublicList>
    </>
  )
}

const Post = ({ match, history }) => {
  const postId = match.params.postulationId

  const { post: postulation, loading: postLoading, error: postError, deletePost, closePost } = usePost({ postId })
  const { vote, loading: voteLoading, saveVote, abstain } = usePostUserVote({ postId })
  const { spawned, loading: spawnedLoading } = useSpawnedPosts({ postId })

  const { user, anonymous } = useContext(AuthContext)

  useEffect(() => {
    window.previousLocation = _get(history, 'location.pathname')
    ReactGA.pageview(`/post${window.location.pathname}`)
    userViewedPost({ postId})
  }, [match.params.postulationId])

  const handleCloseVoting = () => {
    GAEvent({
      category: GA.CATEGORY.POST,
      action: GA.ACTION.CLOSED,
      label: match.params.postulationId
    })
    closePost()
  }

  const onDeletePostulation = () => {
    const doDelete = window.confirm('Are you sure you want to delete this post?')

    if (doDelete) {
      deletePost().then(() => {
        GAEvent({
          category: GA.CATEGORY.POST,
          action: GA.ACTION.DELETED,
          label: postulation.title
        })
        history.push('/')
      }).catch((err) => {
        window.alert('im afraid i cant do that' + err)
      })
    }
  }

  const handleDeletePostulation = () => {
    onDeletePostulation()
  }

  if (postLoading) {
    return <div>Loading</div>
  }

  if (postError) {
    return <div>{postError}</div>
  }

  const closed = postulation.isClosed
  const expired = postulation.isExpired

  return (
    <>
      <MetaTags
        title={`Sortada.com: ${_get(postulation, 'title', '')}`}
        description={postulation.description}
        image={postulation.imgUrl}
      />
      <Container className='App'>
        {anonymous && !closed &&
          <InfoBox>Welcome to Sortada. You've been invited to weigh in on this question by ranking the options below. <Link to='/login'>Login</Link> to submit your rankings, and see the results.</InfoBox>}
        {postulation.hasExpiration && <TimerContainer><span>Time left: </span><Countdown date={postulation.expires} /></TimerContainer>}
        <h1>{postulation.title}</h1>
        <Container direction='row' justify='space-between'>
          <div style={{ flexGrow: 3 }}>
          {postulation.id &&
            !vote && !closed && !expired &&
            _get(user, 'uid') && !anonymous &&
              <Vote key={postulation.id} postulation={postulation} deleteFn={onDeletePostulation} votedFn={onDeletePostulation} saveVote={saveVote} abstain={abstain} />}

          {anonymous && !closed && !expired &&
            <PublicCandidatesList postulation={postulation} />}

          {closed && !vote && <InfoBox>You're late to the party.  This postulation was closed before you could vote... here's the results</InfoBox>}

          {(vote || closed || expired) && <Results key={postulation.id} vote={vote} postulation={postulation} />}
          </div>
          <div>
            <PostImage url={postulation.imgUrl} />
          </div>
        </Container>
        {postulation.sourceId && postulation.sourceId !== 'xxx' && <InfoBox>This is a (re)Sortada based on <Link to={`/postulation/${postulation.sourceId}`}>this Sortada.</Link></InfoBox>}
        {spawned && <Spawned posts={spawned} />}
        {user && !anonymous && <div><Link to={`/add/${postulation.id}`}><Button>This Sortada is insufficient!  (re)Sortada!</Button></Link></div>}

        {_get(user, 'uid') === postulation.ownerId && <div><Button style={deleteStyle} onClick={handleDeletePostulation}>Delete</Button></div>}
        {_get(user, 'uid') === postulation.ownerId && postulation.isActive && <div><Button style={deleteStyle} onClick={handleCloseVoting}>Close Voting</Button></div>}
        {_get(user, 'uid') && <Share title={postulation.title} description={postulation.description} />}
      </Container>
    </>
  )
}

export default Post
