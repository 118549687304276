import styled from 'styled-components'

export default styled.div`
  display: flex;

  > div {
    flex: 4;

    &:first-child {
      margin-right: 2em;
      flex: 8;
    }
  }

  @media(max-width: 899px) {
    flex-direction: column;


    > div {
      margin-right: 0;
      margin-bottom: 1em;

      &:first-child {
        margin-right: 0;
      }
    }
  }
`
