import React from 'react'
import Helmet from 'react-helmet'

const defaultTitle = 'Sortada.com'
const defaultDescription = 'Sort it all out at Sortada.com'
const defaultImage = 'https://sortada.com/ms-icon-310x310.png'

const MetaTags = ({ title = defaultTitle, url, image = defaultImage, description = defaultDescription }) => (
  <Helmet>
    <title>{title}</title>
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta property='description' content={description} />
    <meta property='og:image' content={image} />
    {url && <meta property='og:url' content={url} />}
  </Helmet>
)

export default MetaTags
