import { useEffect, useState } from 'react'
import { getDatabase, ref, query, off, onValue, equalTo, orderByChild } from 'firebase/database'

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

const useFeaturedPosts = () => {
  const [posts, setPosts] = useState()
  const [loading, setLoading] = useState(true)

  const db = getDatabase()

  let postsRef
  useEffect(() => {
    const get = () => {
      postsRef = query(ref(db, 'posts'), orderByChild('isPublic'), equalTo(true))
      onValue(postsRef, (items) => {
        const data = []
        items.forEach((item) => {
          data.push({
            ...item.val(),
            id: item.key
          })
        })
        setPosts(shuffle(data))
        setLoading(false)
      })
    }
    get()
    return () => {
      if (postsRef) { // close the connection
        off(postsRef)
      }
    }
  }, [])

  return {
    posts,
    loading
  }
}

export default useFeaturedPosts