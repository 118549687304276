import { useEffect, useState } from 'react'
import { getDatabase, ref, query, off, onValue, equalTo, orderByChild } from 'firebase/database'

const useSpawnedPosts = ({ postId }) => {
  const [spawned, setSpawned] = useState()
  const [loading, setLoading] = useState(true)

  const db = getDatabase()

  let spawnedRef
  useEffect(() => {
    const get = () => {
      spawnedRef = query(ref(db, 'posts'), orderByChild('sourceId'), equalTo(postId))
      onValue(spawnedRef, (items) => {
        const data = []
        items.forEach((item) => {
          data.push({
            ...item.val(),
            id: item.key
          })
        })

        if (data.length > 0) {
          setSpawned(data)
        } else {
          setSpawned()
        }
        setLoading(false)
      })
    }
    get()
    return () => {
      if (spawnedRef) { // close the connection
        off(spawnedRef)
      }
    }
  }, [postId])

  return {
    spawned,
    loading
  }
}

export default useSpawnedPosts
