import { getAuth } from 'firebase/auth'
import { v4 as uuidV4 } from 'uuid'
import { getDatabase, ref, off, push, set, serverTimestamp } from 'firebase/database'

const otherDefaults = {
  postExpires: '-1', // never by default
  enableOmissions: false,
  omissionThreshhold: '.5',
  isPublic: false,
  isActive: true
}

const createPost = async (values) => {
  const savePath = values.isQuick ? 'quickPosts' : 'posts'

  const sanitizedCandidates = values.candidates
    .reduce((candidate = {}, x, idx) => {
      if (candidate.name !== '') {
        candidate[uuidV4()] = {
          ...x,
          idx
        }
      }

      return candidate
    }, {})

  const expires = values.isQuick
    ? { expires: Date.now() + parseInt(values.quickDuration, 10) }
    : values.postExpires === '-1'
      ? { expires: -1 }
      : { expires: Date.now() + parseInt(values.postExpires, 10) }

  const newData = {
    ...expires,
    ...values,
    ...otherDefaults,
    candidates: sanitizedCandidates,
    createdAt: serverTimestamp(),
    voteCount: 0,
    ownerId: getAuth().currentUser.uid
  }

  delete newData.isQuick
  delete newData.postExpires
  delete newData.quickDuration

  const newDocRef = push(ref(getDatabase(), savePath))

  try {
    await set(newDocRef, newData)
    const newKey = newDocRef.key
    off(newDocRef)
    return Promise.resolve({
      newKey
    })
  } catch (e) {
    return Promise.reject()
  }
}

export default createPost