import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { getAuth } from 'firebase/auth'
import { getDatabase, query, ref, off, orderByChild, equalTo, onValue, set } from 'firebase/database'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// components
import Loading from '../components/Loading'

// styled
import { Button, Container, Input as InputContainer, SingleCenteredColumn } from '../components/styled'

// modules
import getUserName from '../modules/default-user-name'
import GAEvent from '../modules/analytics-event-helper'

// context
import { AuthConsumer } from '../auth/AuthContext'

import { GA } from '../constants'

import 'react-inputs-validation/lib/react-inputs-validation.min.css'

// import { tertiary } from '../variables'

const NameSchema = Yup.object().shape({
  userName: Yup.string()
    .label('User Name')
    .min(3, 'UserName must be at least 3 characters.')
    .max(49, 'UserName must be less than 49 characters.')
    .required('UserName is required.')
    .matches(/^[a-z0-9]+$/i, 'Username must only contain letters and numbers')
})

const ProfileNew = () => {
  const [lookupUserName, setLookupUserName] = useState(getUserName(getAuth().currentUser))
  const [isNameAvailable, setIsNameAvailable] = useState(true)
  const [isSaved, setIsSaved] = useState(false)
  const [hasCheckedName, setHasCheckedName] = useState(false)

  const { errors, values, handleBlur, handleChange, isValid } = useFormik({
    initialValues: {
      userName: getUserName(getAuth().currentUser)
    },
    validationSchema: NameSchema
  })

  useEffect(() => {
    setTimeout(() => {
      ReactGA.pageview('/newProfile')
    }, 750)
  }, [])

  let debounced

  useEffect(() => {
    // if the user name is valid locally, debounce the lookup remotely
    if (isValid) {
      setHasCheckedName(false)
      setIsNameAvailable(false)
      clearTimeout(debounced)
      debounced = setTimeout(() => {
        setLookupUserName(values.userName)
      }, 500)
    }
  }, [values.userName, isValid])

  useEffect(() => {
    GAEvent({
      category: GA.CATEGORY.PROFILE,
      action: GA.ACTION.VERFIY_USERNAME,
      label: values.userName
    })

    const lookupNameRef = ref(getDatabase(), 'profiles')
    onValue(query(lookupNameRef, orderByChild('userNameLower'), equalTo(lookupUserName.toLowerCase())), snapshot => {
      const nameAvailable = snapshot.val() === null

      setIsNameAvailable(nameAvailable)
      setHasCheckedName(true)
    })

    return () => off(lookupNameRef)
  }, [lookupUserName])

  const saveProfile = (values, postSaveFunc) => {
    const saveRef = ref(getDatabase(), `/profiles/${getAuth().currentUser.uid}`)
    set(saveRef, {
      userName: values.userName,
      userNameLower: values.userName.toLowerCase()
    }).then(success => {
      GAEvent({
        category: GA.CATEGORY.PROFILE,
        action: GA.ACTION.CREATED,
        label: getAuth().currentUser.uid
      })

      setIsSaved(true)

      setTimeout(function (userName) {
        postSaveFunc()
      }, 500)()
    }).catch(failed => {

    })
  }

  return (
    <AuthConsumer>
      {({ refreshProfile }) => (
        <Container className='App'>
          <SingleCenteredColumn>
            <h2>Welcome to Sortada...pick a user name and we can get started.</h2>
            <InputContainer>
              <label htmlFor='title'>User Name (3-50 characters)</label>
              <input
                type='text'
                name='userName'
                value={values.userName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!isNameAvailable && hasCheckedName && !isSaved ? <span>User name already taken!</span> : null}
              {errors.userName && <span>{errors.userName}</span>}
              {!isNameAvailable && !hasCheckedName ? <div><Loading /></div> : null}
            </InputContainer>
            <Button
              primary
              type='button'
              onClick={e => saveProfile(values, refreshProfile)}
              disabled={
                  !hasCheckedName ||
                  !isNameAvailable ||
                  !isValid || isSaved
                }
            >Save
            </Button>
          </SingleCenteredColumn>
        </Container>
      )}
    </AuthConsumer>
  )
}

export default ProfileNew
