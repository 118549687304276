import styled from 'styled-components'
import { tertiary } from '../../variables'

export default styled.select`
  color: ${tertiary};
  font-size: 1em;
  padding: 0.3em 1em;
  background: white !important;
  border: 1px solid ${tertiary};
  appearance: menulist;

  &:disabled {
    color: #ababab !important;
    border-color: #999 !important;
    background: transparent !important;
    cursor: not-allowed;
  }
`

// font-size: 1em;

// height: 1.5em;