import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import _get from 'lodash.get'

const userViewedPost = async ({ postId }) => {
  const userViewedPost = httpsCallable(getFunctions(), 'userViewedPostV2')
  const uid = _get(getAuth(), 'currentUser.uid')
  if (uid) {
    // call server function to record the post view
    return userViewedPost({ postId })
  }
}

export default userViewedPost