import React from 'react'

const KofiButton = () => (
  <div>
    <a
      href='https://ko-fi.com/P5P6CI835' 
      target='_blank'>
      <img height='36' style={{ border: 0, height: 36 }} src='https://storage.ko-fi.com/cdn/kofi2.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' />
    </a>
  </div>
)

export default KofiButton