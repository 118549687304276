import styled from 'styled-components'
import { tertiary } from '../../variables'

export default styled.li`
  a {
    transition: 0.2s background-color;
    background: ${tertiary};
    color: white;
    display: block;
    margin-bottom: 8px;
    padding: 1em;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 4px;

    &:hover {
      background-color: #49A550;
    }
  }
`
