import { useEffect, useState } from 'react'

import { getAuth } from 'firebase/auth'
import { getDatabase, off, ref, query, orderByChild, limitToFirst, onValue } from 'firebase/database'

const useScoreHistory = () => {
  const [scoreHistory, setScoreHistory] = useState([])
  const userId = getAuth().currentUser.uid
  const dbHistoryRef = ref(getDatabase(), `userActivity/${userId}/scoreHistory`)

  useEffect(() => {
    onValue(query(dbHistoryRef, orderByChild('timestamp'), limitToFirst(100)), (historySnap) => {
      if (historySnap.val() !== null) {
        const arr = Array.isArray(historySnap.val()) ? historySnap.val() : _values(historySnap.val())
        setScoreHistory(arr
          .reduce((acc, x) => {
            return [x, ...acc]
          }, [])
        )
      }
    })
    return () => off(dbHistoryRef)
  }, [])

  return {
    scoreHistory
  } 
}

export default useScoreHistory